.validation-message {
  padding-top: 5px;
  margin-left: 14px;
  font-size: 0.8em;
  min-height: 1em;
}

.red {
  color: red;
}
