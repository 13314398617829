.project-list {
  display: grid;
}

/* 1 column */
@media only screen and (max-width: 450px) {
  .project-list {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}

/* 2 columns */
@media only screen and (min-width: 450px) {
  .project-list {
    grid-template-columns: repeat(auto-fill, calc(100% / 2));
  }
}

/* 3 columns */
@media only screen and (min-width: 750px) {
  .project-list {
    grid-template-columns: repeat(auto-fill, calc(100% / 3));
  }
}

/* 4 columns */
@media only screen and (min-width: 1100px) {
  .project-list {
    grid-template-columns: repeat(auto-fill, calc(100% / 4));
  }
}
