.loading-error {
  color: red;
  text-align: center;
  padding: 30px 0;
  font-size: large;
}

.divider {
  border-bottom: 1px solid var(--secondary-foreground);
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.project-overview {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .project-overview {
    margin: 20px 15%;
    width: 70%;
  }
}

@media only screen and (max-width: 1000px) {
  .project-overview {
    margin: 20px;
  }
}
