.main {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 650px) {
  .main {
    padding: 1% 0;
    margin: 3% 16% 0 16%;
  }
}

@media only screen and (max-width: 650px) {
  .main {
    padding: 1% 0;
    margin: 3% 3% 0 3%;
  }
}
